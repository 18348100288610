
import '@fortawesome/fontawesome-free/js/fontawesome';
import '@fortawesome/fontawesome-free/js/solid';
// import '@fortawesome/fontawesome-free/js/regular';
import 'modern-css-reset/dist/reset.min.css';
import '../scss/style.scss';





import {Loader} from './loader.js';
import {HeaderController} from './headerController.js';
import {SiteAnimation} from './siteAnimation.js';
import {ContactForm} from './contactForm.js';
import {Modal} from './modal.js';

const loader = new Loader('loader');
const headerController = new HeaderController();
const siteAnimation = new SiteAnimation();
const contactForm = new ContactForm();
const modal = new Modal();


// 慣性スクロール

import Lenis from '@studio-freight/lenis'

const lenis = new Lenis();

lenis.on('scroll', (e) => {
  console.log(e)
})

function raf(time) {
  lenis.raf(time);
  requestAnimationFrame(raf);
}

requestAnimationFrame(raf);