import { gsap } from "gsap";
import { ScrollTrigger } from 'gsap/ScrollTrigger';

gsap.registerPlugin(ScrollTrigger);

export class HeaderController {

  #header;
  #logo;
  #nav;
  #hamburger;
  #headerModal;
  #avoid = 'false';

  constructor(name) {
    this.setElements();
    this.checkAvoid();
    this.setOpposite();
    this.modalControler();
  }

  checkAvoid() {
    this.#avoid = this.#header.classList.contains('js_header_avoid');
    if (!this.#avoid) {
      this.#header.classList.add('active');
    }
  }

  setElements() {
    this.#header = document.getElementById("header");
    this.#logo = this.#header.querySelector('.header_logo');
    // this.#nav = this.#header.querySelector('nav > ul');
    this.#hamburger = this.#header.querySelector('nav .hamburger');
    this.#headerModal = document.getElementById('header_modal');
  }

  setOpposite() {
    const targets = document.querySelectorAll('.js_deaderOpposite');
    const headerHeight = this.#header.clientHeight;

    targets.forEach((target) => {

      gsap.to(this.#header, {
        scrollTrigger: {
          trigger: target,
          start: `top ${headerHeight / 2}`,
          end: `bottom ${headerHeight / 2}`,
          // markers: true,
          onToggle: self => {
            if (self.isActive) {
              this.#header.classList.add('white', 'large');
              this.#headerModal.classList.add('white');
            } else {
              this.#header.classList.remove('white', 'large');
              this.#headerModal.classList.remove('white');
            }
          }
        }
      })
    });
  }

  modalControler() {
    this.#hamburger.addEventListener('click', (e) => {
      // hrefを無効化
      e.preventDefault();
      // 各要素にクラスを追加・削除
      if(this.#hamburger.classList.contains('js_active')) {
        this.#hamburger.classList.remove('js_active');
        this.#headerModal.classList.remove('js_active');
        this.#header.classList.remove('js_modal');
      } else {
        this.#hamburger.classList.add('js_active');
        this.#headerModal.classList.add('js_active');
        this.#header.classList.add('js_modal');
      }
    })
  }
}