import { gsap } from "gsap";
import { ScrollToPlugin } from "gsap/ScrollToPlugin";

gsap.registerPlugin(ScrollToPlugin);

export class Loader {

  #loaderElm;
  #logoAnimateEnd = false;
  #windowLoaded = false;
  #mainTimeline;
  #visited = false;
  #debug = false;

  constructor(id) {
    this.#loaderElm = document.getElementById(id);
    this.setupEventListeners();
    this.#mainTimeline = gsap.timeline();
  }

  setupEventListeners() {
    document.addEventListener('DOMContentLoaded', this.set.bind(this));
    window.addEventListener('load', this.loaded.bind(this));
  }

  set() {
    // 訪問済みなら表示しない & #debugがtrueなら表示する
    this.#visited = sessionStorage.getItem('visited');
    if (this.#visited && !this.#debug) {
      return false;
    }

    this.#loaderElm.classList.add('active');

    //スクロールしないようにする
    document.body.style.overflow = 'hidden';
    // logoSvgのcssアニメーションをセット
    this.logoStart();


  }

  loaded() {
    // load時にアニメーションが動かないようにする
    document.getElementsByTagName('body')[0].classList.remove('preload');
    this.#windowLoaded = true;
    this.checkStart();
  }

  logoStart() {
    const logoSvg = document.querySelector('.loader_logo');
    logoSvg.classList.add('draw');
    logoSvg.addEventListener('animationend', () => {
      this.#logoAnimateEnd = true;
      this.checkStart();
    });
  }

  checkStart() {
    if (this.#logoAnimateEnd && this.#windowLoaded) {
      this.start();
    }
  }

  start() {
    const headerElm = document.getElementById('header');

    //訪問済みを記録
    sessionStorage.setItem('visited', true);

    gsap.set(window, {
      scrollTo: 'min',
    })

    // ローダー画面を消去
    this.#mainTimeline.to('#loader', {
      opacity: 0,
      display: 'none',
      duration: 1,
      ease: 'power4 out',
    })
      .eventCallback("onComplete", () => {
        this.complete()
      })

    // index.htmlだった場合にのみ実行
    var currentPathname = window.location.pathname;
    if (currentPathname.endsWith('/index.html') || currentPathname === '/') {

      this.#mainTimeline
        // headerを上から表示
        .fromTo('header', {
          top: headerElm.clientHeight * -2,
        }, {
          top: '0',
          duration: 1,
        })
        .from('.mainVisual h2', {
          opacity: '0',
          filter: "blur(40px)",
          duration: 1,
        }, '>-.3')
        .from('.mainVisual p', {
          opacity: '0',
          filter: "blur(40px)",
          duration: 1,
        }, '<.5')
    }
  }

  complete() {
    //スクロールできるようにする
    document.body.style.overflow = 'visible';
  }
}