export class Modal {
  #portfolioModalElm;
  #portfolioElm;
  #portfolioModalCloseBtn;

  constructor() {
    this.setElements();
    
    // ポートフォリオがないページの場合処理を中断
    if (!this.#portfolioModalElm) {
      return false;
    }

    this.setPortfolioModalEvent();
  }

  setElements() {
    this.#portfolioModalElm = document.getElementById("portfolio_modal");
    this.#portfolioElm = document.querySelectorAll(".js_portfolio_modal");
    this.#portfolioModalCloseBtn = document.querySelector(
      "#portfolio_modal .modal_closeBtn"
    );
  }

  setPortfolioModalEvent() {
    // ポートフォリオをクリックしたときのイベント
    this.#portfolioElm.forEach((element) => {
      element.addEventListener("click", (e) => {
        e.preventDefault();
        this.showPortfolioModal(element);
      });
    });

    // モーダルのCLOSEボタンをクリックした時のイベント
    this.#portfolioModalCloseBtn.addEventListener("click", (event) => {
      this.hidePortfolioModal();
    });
    // モーダルの外側をクリックしても閉じるよ。
    this.#portfolioModalElm.addEventListener("click", (event) => {
      if (event.target == this.#portfolioModalElm) {
        this.hidePortfolioModal();
      }
    });
  }

  // モーダルウィンドウを表示するよ。
  showPortfolioModal(element) {
    const src = element.getAttribute("data-src");
    const title = element.getAttribute("data-title");
    const url = element.getAttribute("data-url");
    const category = element.getAttribute("data-category");
    const tags = element.getAttribute("data-tags");

    // テキストやファイルパス等を代入する
    this.#portfolioModalElm.querySelector("img").setAttribute("src", src);
    this.#portfolioModalElm.querySelector(".title").innerHTML = title;
    this.#portfolioModalElm.querySelector("a").setAttribute("href", url);
    this.#portfolioModalElm.querySelector("a").textContent = url;
    this.#portfolioModalElm.querySelector(".category").innerHTML = category;
    // 各タグ名の表示・非表示
    if (tags.includes("デザイン")) {
      this.#portfolioModalElm.querySelector(".design").style.display =
        "list-item";
    } else {
      this.#portfolioModalElm.querySelector(".design").style.display = "none";
    }
    if (tags.includes("コーディング")) {
      this.#portfolioModalElm.querySelector(".design").style.display =
        "list-item";
    } else {
      this.#portfolioModalElm.querySelector(".design").style.display = "none";
    }

    // モーダルの表示・非表示
    this.#portfolioModalElm.classList.remove("js_hide");
    this.#portfolioModalElm.classList.add("js_show");
  }

  // モーダルウィンドウを閉じるよ
  hidePortfolioModal(element) {
    this.#portfolioModalElm.classList.remove("js_show");
    this.#portfolioModalElm.classList.add("js_hide");
  }
}
