export class ContactForm {

  #inputs;

  constructor() {

    // contactページでなければ実行しない
    if (this.urlCheck()) {
      this.setup();
    }

  }

  setup() {
    this.#inputs = document.querySelectorAll('input:not([type="submit"]), textarea');
    this.setInputEvent();
    this.setInputDefaultStyle();
    window.addEventListener('load', this.setInputDefaultStyle.bind(this));
  }

  // contactページか確認してboolean値を返す
  urlCheck() {
    var currentPathname = window.location.pathname;
    if (currentPathname.endsWith('/contact.html')) {
      return true;
    } else {
      return false;
    }
  }
  setInputEvent() {
    for (let input of this.#inputs) {
      input.addEventListener('focus', () => {
        input.nextElementSibling.classList.add('focus');
      })
      input.addEventListener('blur', () => {
        input.nextElementSibling.classList.remove('focus');
      })
      input.addEventListener('change', () => {
        if (input.value !== '') {
          input.nextElementSibling.classList.add('completed');
        } else {
          input.nextElementSibling.classList.remove('completed');
        }
      }, false)
    }
  }
  setInputDefaultStyle() {

    for (let input of this.#inputs) {
      if (input.value !== '') {
        console.log('vail');
        input.nextElementSibling.classList.add('completed');
      } else {
        input.nextElementSibling.classList.remove('completed');
      }
  }
  }


  isInputValue(input) {
    if (input.value !== '') {
      input.nextElementSibling.classList.add('completed');
    } else {
      input.nextElementSibling.classList.remove('completed');
    }
  }


}