import { gsap } from "gsap";
import { ScrollTrigger } from 'gsap/ScrollTrigger';

gsap.registerPlugin(ScrollTrigger);


export class SiteAnimation {

  constructor() {
    window.addEventListener('load', this.setAnimation.bind(this));
  }

  setAnimation() {

    // index.htmlの場合のみ
    var currentPathname = window.location.pathname;
    if (currentPathname.endsWith('/index.html') || currentPathname === '/') {
      // MainVisualのパララックス
      gsap.to('.mainVisual .bgImg', {
        top: '-30vh',
        ease: 'none',
        scrollTrigger: {
          trigger: '.mainVisual',
          start: 'bottom bottom',
          end: 'bottom top',
          // markers: true,
          scrub: true,
        }
      })
    }

    // footer帯のパララックス
    gsap.fromTo('.footer_bgImg', {
      top: '10vh',
    },
      {
        top: '-20vh',
        ease: 'none',
        scrollTrigger: {
          trigger: '.separation',
          scrub: true,
        }
      })

    // フェードインする要素
    const fadeInElements = document.querySelectorAll('.js_fadeIn');
    fadeInElements.forEach((targetElm) => {
      gsap.from(targetElm, {
        transform: 'translateY(10vh)',
        autoAlpha: 0,
        duration: 1,
        ease: 'easeIn',
        scrollTrigger: {
          trigger: targetElm,
          start: 'top 90%',
        }
      })
    });
  }



}